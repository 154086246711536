import styled from "styled-components";

import { UserTabsMenuItemStyledProps as Props } from "./UserTabsMenuItem.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const UserTabsMenuItemStyled = styled.div<Props>`
  && .TabsMenuItem {
    padding: 1rem;
    border-radius: 8rem;
  }

  && .TabsMenuItem__title {
    color: var(--palette-gray-s0-l50);
  }

  && .TabsMenuItem__icon {
    path {
      fill: var(--palette-white) !important;

      @media (max-width: ${tablet - 1}px) {
        fill: var(--palette-gray-s0-l50) !important;
      }
    }
  }

  /* & path {
    fill: ${props => (props.active ? "var(--palette-primary)" : "")};
  }

  && .TabsMenuItem__title {
    color: ${props =>
    props.active ? "var(--palette-primary)" : "var(--palette-black-s0-l10)"};
  } */

  .UserTabsMenuItem {
  }
`;

export default UserTabsMenuItemStyled;
