import styled from "styled-components";

import { CartButtonStyledProps as Props } from "./CartButton.types";
import CONSTANTS from "config/constants";

const { WITH_WISHLIST } = CONSTANTS.FEATURE_FLAGS;
const { desktop } = CONSTANTS.BREAKPOINTS;

const CartButtonStyled = styled.div<Props>`
  cursor: pointer;

  &&& .CartButton {
    &__title {
      color: inherit;
      font-weight: 700;
      margin-right: 0.4rem;
      display: none !important;

      &--empty {
        color: var(--palette-white) !important;
      }

      @media (min-width: ${desktop + 1}px) {
        display: block !important;
      }
    }

    &__badge {
      margin-left: 0;

      & .Badge__value {
        background-color: var(--palette-white);
        color: var(--palette-primary);
        font-size: 0.8rem;
        font-weight: 700;
      }
    }

    @media (max-width: ${desktop + 176}px) {
      &__icon {
        margin-left: 0;
      }
    }

    &__button {
      border-radius: 12rem;
      padding: 1.4rem 2.4rem;
      border: 0.2rem solid var(--palette-white);
      transition: all 0.2s ease-in-out;

      @media (max-width: ${desktop}px) {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }

      & svg {
        margin-left: 0 !important;
      }

      &--filled {
        border-color: var(--palette-black-h60-s25-l2-a10);
        background-color: var(--palette-black-h60-s25-l2-a10) !important;

        &:hover:not([disabled]) {
          box-shadow: none !important;
        }
      }
    }

    &--heart {
      display: none;

      path {
        fill: var(--palette-primary);
      }

      @media (hover: none) {
        display: ${props =>
          props.isProductDetails && WITH_WISHLIST && props.isSignedIn
            ? "block"
            : "none"};
      }
    }

    &--cart {
      display: flex;

      @media (max-width: ${desktop - 1}px) {
        margin-left: 0;
      }
    }
  }
`;

export default CartButtonStyled;
