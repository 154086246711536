import { useShoppingCart, useStores } from "@sushicorp/contexts";
import { useCatalogues } from "@sushicorp/contexts";
import { useGeo } from "@sushicorp/contexts";
import { useFetchGoogleAddressFromCoords } from "@sushicorp/services";
import { useFetchNearbyStores } from "@sushicorp/services";
import { getCurrentPosition } from "@sushicorp/utils";
import { Button, Dropdown, Backdrop } from "artisn-ui-react";
import React, { useEffect, useState } from "react";

import { mobileBackdropConfig } from "./SelectAddressDropdown.helpers";
import Styles, { DropdownOverlayStyled } from "./SelectAddressDropdown.styles";
import { SelectAddressDropdownProps as Props } from "./SelectAddressDropdown.types";
import { InfoShoppingCartModal } from "../InfoShoppingCartModal/InfoShoppingCartModal";
import useInfoShoppingCartModal from "../InfoShoppingCartModal/InfoShoppingCartModal.hooks";
import ShippingAddressModal from "components/checkout/ShippingAddressModal/ShippingAddressModal";
import ChooseStoreModal from "components/chooseStore/ChooseStoreModal/ChooseStoreModal";
import ChooseWorkflowSwitch from "components/global/ChooseWorkflowSwitch/ChooseWorkflowSwitch";
import Divider from "components/global/Divider/Divider";
import CONSTANTS from "config/constants";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";
import useBenefit from "hooks/useBenefit/useBenefit";
import { useDeleteShoppingCart } from "hooks/useDeleteShoppingCart";
import useI18n from "hooks/useI18n";
import useValidateStoreGeo from "hooks/useValidateStoreGeo";
import { notify } from "utils/common.utils";
import { createErrorNotification } from "utils/notifications.utils";

import ArrowDropdownSVG from "../../../../public/assets/images/arrow-dropdown.svg";
import CloseSVG from "../../../../public/assets/images/close.svg";
import DeliverySVG from "../../../../public/assets/images/delivery.svg";
import GeoSVG from "../../../../public/assets/images/geo.svg";
import MapSVG from "../../../../public/assets/images/map-orange.svg";
import PickupSVG from "../../../../public/assets/images/pickup.svg";
import StoreSVG from "../../../../public/assets/images/store-orange.svg";

const { FEATURE_FLAGS } = CONSTANTS;
const { WITH_PURCHASE, WITH_TALK_SHOP } = FEATURE_FLAGS;

const SelectAddressDropdown: React.FC<Props> = props => {
  const { className, showIcon = false } = props;
  const [geoCodeAddress, setGeoCodeAddress] = useState<string | undefined>();
  const [openChooseAddress, setOpenChooseAddress] = useState(false);
  const [openChooseStore, setOpenChooseStore] = useState(false);
  const [backdropIsOpen, setBackdropIsOpen] = useState(false);
  const { selectedCoordinates, setSelectedCoordinates } = useGeo();
  const fetchNearbyStores = useFetchNearbyStores(selectedCoordinates, notify);
  const { refetch: refetchNearbyStores } = fetchNearbyStores;
  const { selectedCatalogue } = useCatalogues();
  const { selectedStore, showStoreNotification } = useStores();
  const { setShowStoreNotification, setSelectedStore } = useStores();
  const t = useI18n();
  const { isEmptyShoppingCart, emptyCartHandler } = useDeleteShoppingCart();
  const { storeName } = selectedStore ?? {};
  const { name: selectedCatalogueName } = selectedCatalogue;
  const isDelivery = selectedCatalogueName === "Delivery";
  const { data: predictions, error } = useFetchGoogleAddressFromCoords(
    process.env.NEXT_PUBLIC_MAPS_API_KEY ?? "",
    selectedCoordinates
  );
  const { message } = error ?? {};
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const talkShop = !!talkShopProvider && !!talkShopIdentifier && WITH_TALK_SHOP;
  const { shoppingCart } = useShoppingCart();
  const { keepCartHandler } = useInfoShoppingCartModal();
  const { reApplyBenefit } = useBenefit();
  const { validateStoreGeo } = useValidateStoreGeo();

  const onCurrentLocationHandler = () => {
    if (isDelivery) {
      getCurrentLocation();
      setBackdropIsOpen(false);
      return;
    }

    refetchNearbyStores();
    setBackdropIsOpen(false);
  };

  const locationClickHandler = async () => {
    if (!isEmptyShoppingCart) {
      try {
        const isConfirmed = await InfoShoppingCartModal({});
        if (typeof isConfirmed === "undefined") return;
        if (!isConfirmed) {
          const [benefit] = shoppingCart?.benefits ?? [];
          await getCurrentPosition(async ({ coords }) => {
            const { latitude: lat, longitude: lng } = coords;
            const newStore = await validateStoreGeo({ lat, lng });
            await keepCartHandler({ lat, lng });
            setSelectedStore(newStore);
          });
          onCurrentLocationHandler();
          await reApplyBenefit(benefit);
          return;
        }
        emptyCartHandler();
        onCurrentLocationHandler();
      } catch (e) {
        console.error(e);
        createErrorNotification(e.message);
      }
      return;
    }
    onCurrentLocationHandler();
  };

  const chooseLocationHandler = () => {
    if (isDelivery) {
      setOpenChooseAddress(true);
      setBackdropIsOpen(false);
      return;
    }

    setOpenChooseStore(true);
    setBackdropIsOpen(false);
  };

  const getCurrentLocation = async () => {
    await getCurrentPosition(
      async position => {
        const { latitude, longitude } = position.coords;
        setSelectedCoordinates({ lat: latitude, lng: longitude });
      },
      error => {
        setGeoCodeAddress(error.message);
      }
    );
  };

  useEffect(() => {
    if (!predictions || !predictions.length) return;
    const { formatted_address } = predictions[0];
    setGeoCodeAddress(formatted_address);
  }, [predictions]);

  useEffect(() => {
    if (message) createErrorNotification(message);
  }, [message]);

  useEffect(() => {
    if (showStoreNotification) {
      setOpenChooseStore(false);
      setOpenChooseAddress(false);
    }
  }, [showStoreNotification]);

  const iconNode = (
    <>
      {showIcon ? (
        <>
          {isDelivery ? (
            <DeliverySVG
              className="SelectAddressDropdown__icon"
              viewBox="0 0 16 12"
            />
          ) : (
            <PickupSVG
              className="SelectAddressDropdown__icon"
              viewBox="0 0 18 18"
            />
          )}
        </>
      ) : null}
    </>
  );

  const buttonNode = (
    <Button
      className="SelectAddressDropdown__button"
      onClick={() => setShowStoreNotification(false)}
    >
      {iconNode}
      <div className="SelectAddressDropdown__button-text-container">
        <p className="SelectAddressDropdown__button-text SelectAddressDropdown__button-text--extended">
          {isDelivery ? t.selectAddress.deliverIn : t.common.pickupIn}
        </p>
        <p className="SelectAddressDropdown__button-text">
          {isDelivery
            ? geoCodeAddress || t.common.selectAddress
            : storeName || t.common.selectStore}
        </p>
      </div>
      <ArrowDropdownSVG />
    </Button>
  );

  const overlayNode = (
    <DropdownOverlayStyled className="DropdownOverlay">
      <CloseSVG
        className="DropdownOverlay__close-icon"
        viewBox="0 0 18 18"
        onClick={() => setBackdropIsOpen(false)}
      />
      <ChooseWorkflowSwitch
        className="DropdownOverlay__choose-workflow-desktop"
        onChange={() => setBackdropIsOpen(false)}
      />
      <Button
        className="DropdownOverlay__option"
        type="LINK"
        onClick={chooseLocationHandler}
      >
        <GeoSVG viewBox="0 0 24 24" />
        {isDelivery ? t.common.selectAddress : t.common.selectStore}
      </Button>
      <Divider className="DropdownOverlay__divider" />
      <Button
        className="DropdownOverlay__option DropdownOverlay__select-address"
        type="LINK"
        onClick={locationClickHandler}
      >
        {isDelivery ? (
          <>
            <MapSVG viewBox="0 0 18 18" />
            {t.geo.useCurrentLocation}
          </>
        ) : (
          <>
            <StoreSVG viewBox="0 0 18 18" />
            {t.geo.selectNearbyStore}
          </>
        )}
      </Button>
    </DropdownOverlayStyled>
  );

  if (!WITH_PURCHASE) return null;

  return (
    <Styles
      className={`SelectAddressDropdown ${className}`}
      talkShop={talkShop}
    >
      {showStoreNotification ? (
        // <Dropdown
        //   className="SelectAddressDropdown__dropdown"
        //   overlay={overlayNode}
        //   target={["click"]}
        //   position={"left"}
        //   isFixed
        //   showDropdown={false}
        //   backdropConfig={{
        //     opacity: 0.25,
        //     color: "hsla(0, 0%, 0%, 1)"
        //   }}
        // >
        //   {buttonNode}
        // </Dropdown>
        <></>
      ) : (
        <Dropdown
          className="SelectAddressDropdown__dropdown"
          overlay={overlayNode}
          target={["click"]}
          position={"left"}
          isFixed
          backdropConfig={{
            opacity: 0.25,
            color: "hsla(0, 0%, 0%, 1)"
          }}
        >
          {buttonNode}
        </Dropdown>
        // <></>
      )}
      <div
        className="SelectAddressDropdown__button-container"
        onClick={() => setBackdropIsOpen(true)}
      >
        {buttonNode}
      </div>
      <Backdrop
        {...mobileBackdropConfig}
        opened={backdropIsOpen}
        onClick={() => setBackdropIsOpen(false)}
      >
        {overlayNode}
      </Backdrop>
      <ShippingAddressModal
        opened={openChooseAddress}
        onClose={() => setOpenChooseAddress(false)}
        locationOnly
      />
      <ChooseStoreModal
        opened={openChooseStore}
        onClose={() => setOpenChooseStore(false)}
      />
    </Styles>
  );
};

SelectAddressDropdown.defaultProps = {
  className: ""
};

export default SelectAddressDropdown;
